import VehiclesRegPanel from "../../../components/shared/drivers/vehiclesRegPanel";
import {
  ContentCopyIcon,
  FuelSuccessShake,
  PrintIcon,
  ShareIcon,
} from "assets/icons";
import Avatar from "components/shared/Avatar";
import { Button } from "components/shared/Button";
import React from "react";

const FuelBuyerGenerateCode = () => {
  const initWindow = typeof window !== "undefined" && window;
  const printPage = () => initWindow.print();
  return (
    <div className="tw-w-full tw-flex tw-mt-10 tw-justify-center tw-mx-auto md:tw-w-[538px] tw-bg-white tw-py-8 tw-px-5 md:tw-px-8 tw-rounded-lg">
      <div>
        <h4 className="tw-text-secondary-800 tw-text-md tw-text-medium tw-text-center">
          Transaction Successful!
        </h4>
        <center>
          <FuelSuccessShake />
        </center>

        <div
          className="tw-bg-white tw-w-full tw-flex tw-items-center tw-justify-between tw-py-5 tw-px-5 tw-rounded-lg"
          style={{
            boxShadow: "0 4px 16px  0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="tw-m-0 tw-flex tw-items-center tw-gap-3 ">
            <ShareIcon />
            <p className="tw-text-semibold tw-m-0">Share</p>
          </div>

          <h4 className="tw-font-semibold tw-m-0">XV67H</h4>

          <div className="tw-cursor-pointer">
            <ContentCopyIcon />
          </div>
        </div>

        <div className="tw-w-[80%] tw-mx-auto tw-my-8 ">
          <p className=" sm:tw-text-sm md:tww-text-md tw-text-secondary-700 tw-text-center">
            Here is your order details, copy and share to the respective
            reciepient
          </p>
        </div>

        <div className="tw-flex tw-items-center tw-mb-5 tw-gap-5">
          <VehiclesRegPanel bg="white" />

          <div className="  tw-border-1-gray-200 tw-w-full tw-h-[124px] tw-flex tw-justify-center  tw-w-[120px] tw-h-full tw-rounded-lg tw-border tw-px-3 tw-py-3">
            <div className="tw-text-center ">
              <center>
                <Avatar
                  size="md"
                  className={"tw-my-3"}
                  src="https://avatars.githubusercontent.com/u/54102389?v=4"
                />
              </center>
              <h6 className="tw-font-semibold">Mr Temitayo Ojo</h6>
            </div>
          </div>
        </div>

        {/* selected fuel station section */}
        <div
          className="tw-w-full tw-py-6 tw-rounded-lg"
          style={{
            boxShadow: "0 4px 16px  0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <h5 className="tw-font-medium tw-px-6  tw-pb-">
            Selected Petrol Station
          </h5>
          <hr />
          <div className="tw-mt-5 tw-px-6 ">
            <p className="tw-text-sm">
              Here are the lst of fuel stations that your code is eligible for.
              Note that you cannot use the above code more than once.
            </p>

            <div className="tw-mt-5"></div>
          </div>
        </div>

        <center>
          <Button
            onClick={printPage}
            className="tw-mt-8 tw-bg-secondary-800 tw-text-white"
          >
            <PrintIcon />
            Print Page{" "}
          </Button>
        </center>
      </div>
    </div>
  );
};

export default FuelBuyerGenerateCode;
