import PageTitleHeader from "../../../components/shared/titleHeader";
import {
  GasStationActiveIcon,
  VehicleActiveIcon,
} from "assets/icons/dashboardIcons";
import { ROUTE_ENUMS } from "enums";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

const SelectFuelPurchaseTypeView = () => {
  const navigate = useNavigate();
  const purchaseType = [
    {
      title: "Vehicle Purchase",
      icon: <VehicleActiveIcon />,
      description: "Purchase fuel",
      path: `${ROUTE_ENUMS.DASHBOARD_FUEL_STATION_PURCHASE}/select-fuelstation`,
    },
    {
      title: "Others",
      icon: <GasStationActiveIcon />,
      description: "Purchase for Gallons, cylinders and other cans.",
      path: `${ROUTE_ENUMS.DASHBOARD_FUEL_STATION_PURCHASE}/order`,
    },
  ];

  const [isSelected, setIsSelected] = React.useState({
    title: "",
    path: "",
  });

  const onSelectionChange = (title, path) => {
    setIsSelected({
      title: title.toLowerCase(),
      path,
    });

    setTimeout(() => navigate(path), 500);
  };

  return (
    <div className="container tw-w-[90%] tw-mx-auto tw-mt-5">
      <PageTitleHeader
        title="Purchase Fuel"
        description="Select your preferred fuel type and amount below to refuel your vehicle quickly and easily."
      />
      <div className="tw-mt-[3rem] tw-gap-5 tw-flex tw-flex-col md:tw-flex-row lg:tw-flex-row tw-justify-between">
        {purchaseType.map(({ title, description, icon, path }, index) => {
          const isActive = isSelected.title === title.toLowerCase();
          const style = isActive
            ? "tw-border tw-border-1 tw-border-primary-800"
            : "";
          return (
            <div
              className={`tw-w-full tw-bg-white tw-rounded-2xl tw-cursor-pointer tw-p-[20px]
                                     md:tw-p-[24px] lg:tw-p-[24px] ${style} hover:tw-border hover:tw-border-1 hover:tw-border-primary-800`}
              onClick={() => onSelectionChange(title, path)}
              key={index}
            >
              <div className="tw-border tw-border-primary-200 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-[30px] tw-h-[30px]">
                {icon}
              </div>
              <h5 className="tw-font-semibold sm:tw-text-sm md:tw-text-md lg:tw-text-lg tw-my-3 tw-text-black">
                {title}
              </h5>
              <p className="tw-text-[14px] tw-text-gray-600">{description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectFuelPurchaseTypeView;
