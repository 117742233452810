import { FUEL_TYPES } from "../../Drivers/Form/constants";
import {
  CODE_VALIDITY_VARIANTS,
  SELECT_CUSTOM_CONTROL_STYLES,
  initialValues,
  buyFuelSchema,
} from "../constants";
import { EditIcon } from "assets/icons";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import { ROUTE_ENUMS } from "enums";
import { Formik, Field, Form } from "formik";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { createFuelBuyerRequest } from "state/slices/fuel";
import { getOneLinerErrorMessage } from "utilities";

const FuelBuyerPurchaseOrderPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState();
  const selectedStations = location.state?.selectedStations || [];

  const editSelectedFuelStations = () =>
    navigate(
      `${ROUTE_ENUMS.DASHBOARD_FUEL_STATION_PURCHASE}/select-fuelstation`,
      {
        state: { selectedStations },
      }
    );

  const fuelStationIds = selectedStations.map((stations) => {
    return stations.id;
  });

  const fallBackUrl = `${window.location.origin}/dashboard/fuel-station/purchase/generate-code`;
  console.log(fallBackUrl);

  const createPurchase = async (formValues, formikHelpers) => {
    setIsLoading(true);
    const payload = {
      data: {
        ...formValues,
        productType: formValues.productType.value?.toUpperCase(),
        redirectUrl: fallBackUrl,
        fuelStationIds,
      },
    };

    const resultAction = await dispatch(createFuelBuyerRequest(payload.data));

    if (createFuelBuyerRequest.fulfilled.match(resultAction)) {
      formikHelpers.resetForm();
      setIsLoading(false);
      toast.success("Successfully created purchase order");
      const { payload } = resultAction;
      if (payload?.paymentUrl) {
        window.location.href = payload.paymentUrl;
      } else {
        toast.error("Payment URL not found. Please try again.");
      }
    } else {
      setIsLoading(false);
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <div className=" tw-flex  tw-flex-wrap tw-gap-5 tw-justify-between">
      <div
        className="tw-bg-white   tw-w-full   md:tw-w-[30%] lg:tw-w-[35%] tw-py-8 tw-px-5 md:tw-px-5 lg:tw-px-5 tw-rounded-lg  tw-border tw-border-3-gray-700
                tw-h-fit"
      >
        <div className="tw-flex tw-justify-between">
          <div>
            <small className="tw-font-semibold">Fuel Stations</small>
            {/* <p>Victoria Island</p> */}
          </div>

          <div className="tw-cursor-pointer" onClick={editSelectedFuelStations}>
            <EditIcon />
          </div>
        </div>

        <div className="tw-mt-8">
          {selectedStations?.map((station, key) => {
            return (
              <Fragment key={key}>
                <div className="tw-flex tw-justify-between  tw-mt-2 tw-items-center tw-h-20 tw-px-3 md:tw-px-4 lg:tw-px-4 tw-rounded-lg tw-bg-gray-50 tw-cursor-pointer">
                  <div className="tw-flex tw-items-center tw-gap-4 tw-h-full tw-m-0">
                    <img
                      src={station.logoUrl || "/images/logo.svg"}
                      width={60}
                      height={100}
                    />
                    <p className="tw-text-[12px] tw-m-0 tw-font-semibold">
                      {station?.name}
                    </p>
                  </div>
                  <div className="tw-h-full tw-flex tw-items-center tw-m-0">
                    <p className="tw-font-semibold tw-text-sm tw-m-0">
                      N{station?.price}/ltr
                    </p>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>

      <section
        className="tw-bg-white tw-py-8 tw-px-5 md:tw-px-8 lg:tw-px-8 tw-rounded-md
            tw-w-full   md:tw-w-[60%] lg:tw-w-[60%] "
      >
        <h4 className="tw-font-semibold">Purchase Summary</h4>

        <div className="tw-mt-[35px]">
          <Formik
            initialValues={initialValues}
            validationSchema={buyFuelSchema}
            onSubmit={createPurchase}
            enableReinitialize
          >
            {({ handleSubmit, errors }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="">
                    <div className="form-group tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 ">
                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input"
                          name="buyerName"
                          id="buyerName"
                          placeholder="John Doe"
                          label="Buyer Name"
                          required
                        />
                      </div>

                      <div>
                        <Field
                          component={TextInput}
                          name="email"
                          id="email"
                          className="tw-input"
                          placeholder="Johndoe@gmail.com"
                          label="Email"
                          required
                        />
                      </div>
                    </div>

                    <div className="tw-m-0">
                      {/* <Field
                        component={ThemedSelect}
                        name="codeValidity"
                        id="codeValidity"
                        placeholder="Select Code Validity"
                        label="Select Code Validity"
                        options={CODE_VALIDITY_VARIANTS}
                        customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                        required
                      /> */}
                    </div>

                    <div className=" form-group tw-mt-7 tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 ">
                      <div>
                        <Field
                          component={TextInput}
                          name="amount"
                          id="amount"
                          className="tw-input"
                          placeholder="0.00"
                          label="Amount"
                          required
                        />
                      </div>
                      <div>
                        <Field
                          component={ThemedSelect}
                          name="productType"
                          id="productType"
                          placeholder="Fuel Type"
                          label="Petrol"
                          options={FUEL_TYPES}
                          customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="tw-flex tw-gap-3 tw-justify-end tw-items-center tw-mt-6">
                    <Button className="tw-px-4">Back</Button>
                    <Button
                      type="submit"
                      className="tw-border-none tw-px-4  tw-rounded-md tw-bg-secondary-700 tw-text-white"
                      variant="rounded"
                      loading={isLoading}
                      // disabled={isLoading || !isValid}
                    >
                      {isLoading ? "Generating..." : "Generate Code"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default FuelBuyerPurchaseOrderPage;
