import { FUEL_PRODUCTS } from "constants/global";
import * as yup from "yup";

export const initialValues = {
  buyerName: "",
  email: "",
  productType: "",
  amount: "",
  codeValidity: 24,
};

export const FORM_TYPES = ["Vehicle", "Other"];

export const validationSchema = yup.object().shape({
  title: yup.object().label("Title").required(),
  firstName: yup.string().label("First Name").required(),
  lastName: yup.string().label("Last Name").required(),
  email: yup.string().label("Email").required(),
  phone: yup
    .string()
    .label("Contact Number")
    .matches(/^([0-9\s\-\+\(\)]*)$/, "Phone number is invalid")
    .optional(),
});

export const CAR_TYPES = ["Car", "Bus", "SUV"].map((item) => ({
  value: item,
  label: item,
}));

export const FUEL_TYPES = ["Petrol", "Diesel"].map((item) => ({
  value: item,
  label: item,
}));

export const SELECT_CUSTOM_CONTROL_STYLES = {
  padding: "4px",
  borderRadius: "10px",
  border: "1px solid #d1d5db",
};

export const MEDIUMS = ["Vehicle", "Keg"].map((item) => ({
  value: item,
  label: item,
}));

export const buyFuelSchema = yup.object().shape({
  buyerName: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  amount: yup
    .number()
    .positive("Amount must be a positive number")
    .required("Amount is required"),
  redirectUrl: yup.string().url("Must be a valid URL"),
  // .required("Redirect URL is required"),
});

export const CODE_VALIDITY_VARIANTS = [
  "1 hour",
  "30 minutes",
  "2 hours",
  "2 hours 30minutes",
  "4 hours",
].map((item) => ({
  value: item,
  label: item,
}));
